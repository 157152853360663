import { useMainMachine } from '../../../utils/useMainMachine';
import { TextSuggestions } from './TextSuggestions';
import { ImageSuggestions } from './ImageSuggestions';

export function AISuggestions({ usedVoice, setUsedVoice }) {
  const { useSelector } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);

  if (selectedElement.imgProps?.url) {
    return <ImageSuggestions usedVoice={usedVoice} setUsedVoice={setUsedVoice} />;
  } else {
    return <TextSuggestions usedVoice={usedVoice} setUsedVoice={setUsedVoice} />;
  }
}
