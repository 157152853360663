import { useLayoutEffect } from 'react';
import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { ACTIONS } from '../../../state/actions';

export function NarrowSelectedElement() {
  const { useSelector, machine } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);
  const childNodes = selectedElement.editableChildNodes;

  useLayoutEffect(() => {
    if (selectedElement.editableChildNodes?.length === 1) {
      machine.send({ type: ACTIONS.SELECT_ELEMENT, selector: selectedElement.editableChildNodes[0].selector });
    }
  }, [selectedElement, machine]);

  return (
    <div data-testid="selection-helper" className="flex flex-col">
      <div>Select the element you want to edit:</div>
      {childNodes.map((n) => (
        <button
          type="button"
          key={n.selector}
          className={classNames(
            'relative text-sm text-left mt-2.5 p-2.5 rounded-md border ',
            'bg-gray-200/10 text-gray-200 hover:text-white border-gray-200/50 hover:border-gray-200',
          )}
          onClick={() => machine.send({ type: ACTIONS.SELECT_ELEMENT, selector: n.selector })}
        >
          <div className="line-clamp-2">{n.textContent.join(' > ')}</div>
        </button>
      ))}
    </div>
  );
}
