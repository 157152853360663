import { MobileItem, MobileCategory } from './components/_top/MobileSelectItems';

export const AI_FEEDBACK_TYPES = {
  IMPLICIT_POSITIVE: 'implicit_positive_feedback',
  EXPLICIT_POSITIVE: 'explicit_positive_feedback',
  EXPLICIT_NEGATIVE: 'explicit_negative_feedback',
};

export const DEVICES = {
  DESKTOP: 'desktop',
  MOBILE: 'phone',
  TABLET: 'tablet',
};

export const MOBILE_TYPES = {
  IPHONE_15_PRO_MAX: 'iPhone15ProMax',
  IPHONE_15_PLUS: 'iPhone15Plus',
  IPHONE_14_PRO: 'iPhone14Pro',
  IPHONE_12: 'iPhone12',
  IPHONE_X: 'iPhoneX',
  IPHONE_6: 'iPhone6',
  PIXEL_8_PRO: 'Pixel8Pro',
  PIXEL_6_PRO: 'Pixel6Pro',
  PIXEL_6: 'Pixel6',
  PIXEL_5: 'Pixel5',
  PIXEL_4: 'Pixel4',
  GALAXY_S22_ULTRA: 'GalaxyS22Ultra',
  GALAXY_S22_PLUS: 'GalaxyS22Plues',
  GALAXY_S22: 'GalaxyS22',
  GALAXY_S21_ULTRA: 'GalaxyS21Ultra',
  GALAXY_S21_PLUS: 'GalaxyS21PLUS',
  GALAXY_S21: 'GalaxyS21',
};

export const MOBILE_OPTIONS = [
  {
    value: 'apple',
    label: <MobileCategory name="Apple" />,
    disabled: true,
  },
  {
    value: MOBILE_TYPES.IPHONE_15_PRO_MAX,
    label: <MobileItem name="iPhone 15 Pro Max" dimension="430x932" />,
    width: 1290,
    dpr: 3,
  },
  {
    value: MOBILE_TYPES.IPHONE_15_PLUS,
    label: <MobileItem name="iPhone 15 Plus, 14 Pro Max" dimension="430x932" />,
    width: 1290,
    dpr: 3,
  },
  {
    value: MOBILE_TYPES.IPHONE_14_PRO,
    label: <MobileItem name="iPhone 15, 14 Pro" dimension="393x852" />,
    width: 1179,
    dpr: 3,
  },
  {
    value: MOBILE_TYPES.IPHONE_12,
    label: <MobileItem name="iPhone 12, 13, 14" dimension="390x844" />,
    width: 1170,
    dpr: 3,
  },
  {
    value: MOBILE_TYPES.IPHONE_X,
    label: <MobileItem name="iPhone X, XS" dimension="375x812" />,
    width: 1125,
    dpr: 3,
  },
  {
    value: MOBILE_TYPES.IPHONE_6,
    label: <MobileItem name="iPhone 6, 6S, 7, 8, SE" dimension="375x667" />,
    width: 750,
    dpr: 2,
  },
  {
    value: 'google',
    label: <MobileCategory name="Google" />,
    disabled: true,
  },
  {
    value: MOBILE_TYPES.PIXEL_8_PRO,
    label: <MobileItem name="Pixel 8 Pro" dimension="448x998" />,
    width: 1344,
    dpr: 3,
  },
  {
    value: MOBILE_TYPES.PIXEL_6_PRO,
    label: <MobileItem name="Pixel 6 Pro, 7 Pro" dimension="412x892" />,
    width: 1440,
    dpr: 3.5,
  },
  {
    value: MOBILE_TYPES.PIXEL_6,
    label: <MobileItem name="Pixel 5a, 6, 6a, 7, 7a, 8" dimension="412x915" />,
    width: 1080,
    dpr: 2.625,
  },
  {
    value: MOBILE_TYPES.PIXEL_5,
    label: <MobileItem name="Pixel 4a, 5" dimension="393x851" />,
    width: 1080,
    dpr: 2.75,
  },
  {
    value: MOBILE_TYPES.PIXEL_4,
    label: <MobileItem name="Pixel 4" dimension="393x830" />,
    width: 1080,
    dpr: 2.75,
  },
  {
    value: 'samsung',
    label: <MobileCategory name="Samsung" />,
    disabled: true,
  },
  {
    value: MOBILE_TYPES.GALAXY_S22_ULTRA,
    label: <MobileItem name="Galaxy S22 Ultra" dimension="384x824" />,
    width: 1440,
    dpr: 3.75,
  },
  {
    value: MOBILE_TYPES.GALAXY_S22_PLUS,
    label: <MobileItem name="Galaxy S22+" dimension="384x832" />,
    width: 1080,
    dpr: 2.8125,
  },
  {
    value: MOBILE_TYPES.GALAXY_S22,
    label: <MobileItem name="Galaxy S22" dimension="360x780" />,
    width: 1080,
    dpr: 3,
  },
  {
    value: MOBILE_TYPES.GALAXY_S21_ULTRA,
    label: <MobileItem name="Galaxy S21 Ultra" dimension="412x915" />,
    width: 1080,
    dpr: 2.625,
  },
  {
    value: MOBILE_TYPES.GALAXY_S21_PLUS,
    label: <MobileItem name="Galaxy S21+" dimension="384x854" />,
    width: 1080,
    dpr: 2.8125,
  },
  {
    value: MOBILE_TYPES.GALAXY_S21,
    label: <MobileItem name="Galaxy S21" dimension="360x800" />,
    width: 1080,
    dpr: 3,
  },
];

export const VOICES = {
  LUCKY: 'lucky',
  PROFESSIONAL: 'professional',
  FRIENDLY: 'friendly',
  CONFIDENT: 'confident',
  REASSURING: 'reassuring',
  ENGAGING: 'engaging',
};

export const VOICE_OPTIONS = [
  {
    value: VOICES.LUCKY,
    label: <span className="text-white">I&apos;m feeling lucky!</span>,
  },
  {
    value: VOICES.PROFESSIONAL,
    label: <span className="text-voice-professional">Professional</span>,
  },
  {
    value: VOICES.FRIENDLY,
    label: <span className="text-voice-friendly">Friendly</span>,
  },
  {
    value: VOICES.CONFIDENT,
    label: <span className="text-voice-confident">Confident</span>,
  },
  {
    value: VOICES.REASSURING,
    label: <span className="text-voice-reassuring">Reassuring</span>,
  },
  {
    value: VOICES.ENGAGING,
    label: <span className="text-voice-engaging">Engaging</span>,
  },
];

export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  PUBLISH: 'publish',
  CANCEL: 'cancel',
};

export const NOTIFICATION_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

export const DEVICE_TO_INT = {
  [DEVICES.DESKTOP]: 0,
  [DEVICES.MOBILE]: 1,
  [DEVICES.TABLET]: 2,
};

export const LOADING = 'loading';
export const METRICS_PREFIX = 'editor';

export const SUGGESTION_COUNT = 3;

export const AI_IMAGE_MODEL = 'flux_pro_multi11';
export const AI_IMAGE_API = 'https://ai.crazyegg.com/api/image/text_variation';
