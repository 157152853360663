import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { STATES } from '../../../state';
import { Pages } from './Pages';
import { Versions } from './Versions';
import { Controls } from './Controls';

export function SideBar() {
  const { useSelector } = useMainMachine();
  const sideBarOpen = useSelector(
    (state) =>
      state.context.editor.pages.length > 1 ||
      state.matches({ [STATES.INITIALIZED]: { [STATES.EDITOR]: STATES.EDIT } }) ||
      state.matches({ [STATES.INITIALIZED]: { [STATES.EDITOR]: STATES.AI_SUGGESTIONS } }) ||
      state.context.editor.pages.reduce((acc, p) => acc + (p.versions?.length ?? 0), 0) > 0,
  );

  if (!sideBarOpen) return null;

  return (
    <div
      className={classNames('w-[280px] grow-0 shrink-0 bg-gray-900 p-5', 'overflow-auto styled-scrollbar')}
      data-testid="sidebar"
    >
      <Pages />
      <Versions />
      <Controls />
    </div>
  );
}
