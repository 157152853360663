export const ACTIONS = {
  CHANGE_MODE: 'changeMode',
  CHANGE_DEVICE: 'changeDevice',
  SHOW_ADD_PAGE: 'showAddPage',
  ADD_PAGE: 'addPage',
  CHANGE_VOICE: 'changeVoice',
  ELEMENT_SELECTED: 'elementSelected',
  ELEMENT_UNSELECTED: 'elementUnSelected',
  SELECTED_ELEMENT_UPDATED: 'selectedElementUpdated',
  ELEMENT_LOCKED: 'elementLocked',
  POPUPS_DETECTED: 'popupsDetected',
  HIDE_POPUPS: 'hidePopups',
  SAVE: 'save',
  SAVE_AS_NEW: 'saveAsNew',
  UPDATE_TEXT: 'updateText',
  SELECT_PAGE: 'selectPage',
  SELECT_VERSION: 'selectVersion',
  UNDO: 'undo',
  REDO: 'redo',
  DIRTY_CHANGED: 'dirtyChanged',
  DISCARD: 'discard',
  CANCEL: 'cancel',
  SHOW_HISTORY: 'showHistory',
  STATUS_CHANGE: 'statusChange',
  GENERATE_FULL_PAGE: 'generateFullPage',
  AI_REPHRASE: 'aiRephrase',
  BACK: 'back',
  SET_SELECTED_EL_TEXT: 'setSelectedElText',
  SET_SELECTED_EL_HTML: 'setSelectedElHTML',
  SET_SELECTED_EL_ATTRIBUTE: 'setSelectedElAttribute',
  SET_SELECTED_EL_IMAGE: 'setSelectedElImage',
  PUBLISH: 'publish',
  TOGGLE_PAGES: 'togglePages',
  TOGGLE_VERSIONS: 'toggleVersions',
  TOGGLE_CONTROLS: 'toggleControls',
  SELECT_ELEMENT: 'selectElement',
  ERROR_DETECTED: 'errorDetected',
  IMAGE_GENERATION_ERROR: 'imageGenerationError',
};
