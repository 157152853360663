import { useMainMachine } from '../../../utils/useMainMachine';

import { Button } from '../basic-ui/Button';
import { Spinner } from '../basic-ui/Spinner';
import { ACTIONS } from '../../../state';
import { STATES } from '../../../state/states';

import SparklesIcon from '@crazyegginc/hatch/dist/images/icon-sparkles-filled.svg?react';

export function Generate() {
  const { machine, useSelector } = useMainMachine();
  const voice = useSelector(({ context }) => context.voice);
  const isGenerating = useSelector((state) =>
    state.matches({ [STATES.INITIALIZED]: { [STATES.GENERAL]: STATES.GENERATING_FULL_PAGE } }),
  );

  return (
    <Button disabled={isGenerating} onClick={() => machine.send({ type: ACTIONS.GENERATE_FULL_PAGE, voice })}>
      <div className="flex items-center space-x-2">
        {!isGenerating ? (
          <>
            <SparklesIcon className="w-4 h-4 fill-current" />
            <span>Generate</span>
          </>
        ) : (
          <>
            <Spinner className="w-4 h-4" />
            <span>Generating...</span>
          </>
        )}
      </div>
    </Button>
  );
}
