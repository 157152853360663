//import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { inflect /*capitalizeFirstLetter*/ } from '../../../utils/string';
//import { VOICES } from '../../constants';

export function WordsChanged() {
  const { useSelector } = useMainMachine();
  const wordsChanged = useSelector(({ context }) => context.wordsChanged);
  const imagesChanged = useSelector(({ context }) => context.imagesChanged);
  //const usedVoice = useSelector(({ context }) => context.metadata?.voice);

  return (
    <div className="ml-5 text-gray-200 whitespace-pre" data-testid="words-changed">
      {wordsChanged} {inflect('word', wordsChanged)}
      {` and ${imagesChanged} ${inflect('image', imagesChanged)}`} changed
      {/*usedVoice ? (
        <>
          {' '}
          to
          <span
            className={classNames('whitespace-pre', {
              'text-voice-confident': usedVoice === VOICES.CONFIDENT,
              'text-voice-engaging': usedVoice === VOICES.ENGAGING,
              'text-voice-friendly': usedVoice === VOICES.FRIENDLY,
              'text-voice-professional': usedVoice === VOICES.PROFESSIONAL,
              'text-voice-reassuring': usedVoice === VOICES.REASSURING,
            })}
          >
            {' '}
            {capitalizeFirstLetter(usedVoice)} voice
          </span>
        </>
      ) : null*/}
    </div>
  );
}
