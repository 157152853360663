import { useState } from 'react';

import { useMainMachine } from '../../../utils/useMainMachine';
import { TextArea } from '../basic-ui/TextArea';
import { ACTIONS } from '../../../state/actions';
import { isStringHTML } from '../../../utils/html';

function splitTags(str) {
  const i = str.indexOf('</');
  if (i === -1) return str;
  return [str.slice(0, i), str.slice(i)];
}

export function HtmlContent() {
  const { useSelector, machine } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);

  // for input elements we can edit the placeholder text instead
  const isInput = selectedElement.tagName === 'INPUT';

  const [value, setValue] = useState(isInput ? selectedElement.placeholder.trim() : selectedElement.innerHTML.trim());

  const isHTML = isInput ? false : isStringHTML(selectedElement.innerHTML);

  // we use innerHTML to display, but we need to send the full HTML to the API
  const wrapperTag = splitTags(selectedElement.html.replace(/^\s*(<[^>]*>)*.*?(\s*<\/[^>]*>)*\s*$/s, `$1$2`));

  return (
    <TextArea
      value={value}
      label="Content"
      onChange={(e) => {
        const v = e.target.value;
        setValue(v);
        if (isInput) {
          machine.send({
            type: ACTIONS.SET_SELECTED_EL_ATTRIBUTE,
            attribute: 'placeholder',
            value: v,
          });
        } else {
          if (isHTML) {
            machine.send({
              type: ACTIONS.SET_SELECTED_EL_HTML,
              html: wrapperTag[0] + v + wrapperTag[1],
            });
          } else {
            machine.send({
              type: ACTIONS.SET_SELECTED_EL_TEXT,
              text: v,
            });
          }
        }
      }}
      labelClassName="!font-normal !text-gray-200"
    />
  );
}
