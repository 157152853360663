import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { ACTIONS } from '../../../state/actions';

import HistoryIcon from '@crazyegginc/hatch/dist/images/icon-history.svg?react';

export function History() {
  const { machine, useSelector } = useMainMachine();
  const hasHistory = useSelector(
    ({ context }) => context.editor.pages.find((p) => p.id === context.activePageId)?.history?.length > 0,
  );

  const disabled = !hasHistory;

  return (
    <button
      type="button"
      disabled={!hasHistory}
      onClick={() => machine.send({ type: ACTIONS.SHOW_HISTORY })}
      className={classNames(
        'rounded-md duration-75 hover:transition-all h-[30px] px-2.5',
        'flex items-center justify-center space-x-2',
        'outline-none focus:outline-none focus-visible:outline-white focus-visible:outline-dashed',
        {
          'text-gray-200 hover:bg-gray-600 cursor-pointer active:text-white active:bg-gray-500': !disabled,
          'cursor-not-allowed text-white/30': disabled,
        },
      )}
    >
      <HistoryIcon className="w-4 h-4 fill-current" />
      <span>History</span>
    </button>
  );
}
