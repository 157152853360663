import * as yup from 'yup';
import { Formik, Form } from 'formik';

import { useMainMachine } from '../../../utils/useMainMachine';
import { getElementFromString } from '../../../utils/html';
import { Input } from '../basic-ui/Input';
import { Button } from '../basic-ui/Button';
import { ACTIONS } from '../../../state';

import TickIcon from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg?react';

const validationSchema = yup.object().shape({
  url: yup
    .string()
    .required('Please provide a value.')
    .test('no data url', 'Data URLs are not accepted.', (value) => !/^data:/.test(value)),
});

export function ImageContent() {
  const { useSelector, machine } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);

  return (
    <div>
      <img
        src={selectedElement.imgProps.url}
        alt={getElementFromString(selectedElement.html).alt || ''}
        width="240px"
      />
      <Formik
        initialValues={{ url: selectedElement.imgProps.url }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          machine.send({ type: ACTIONS.SET_SELECTED_EL_IMAGE, url: values.url });
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, dirty }) => (
          <Form>
            <div className="mt-2.5 flex items-start">
              <Input
                label="Source"
                name="url"
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.url && errors.url ? errors.url : null}
              />
              <Button type="submit" className="mt-[19px] ml-2.5 shrink-0" disabled={!dirty}>
                <TickIcon className="h-3 w-3 fill-current" aria-label="change image" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
