import { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Transition } from '@headlessui/react';

import { useMainMachine } from '../../../utils/useMainMachine';
import { useCrazyAI } from '../../../ai/useCrazyAI';
import { CrazyAIQueryContext } from '../../../ai/CrazyAI';
import { VoiceAndGenerate } from './VoiceAndGenerate';
import { getVoice, loadImage } from '../../../utils/utils';
import { toDataURL, getElementFromString } from '../../../utils/html';
import { VOICES, SUGGESTION_COUNT, AI_IMAGE_MODEL, AI_IMAGE_API, METRICS_PREFIX } from '../../constants';
import { ACTIONS } from '../../../state/actions';
import { sendMetric } from '../../../utils/metrics';

import TickIcon from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg?react';

const queryKeys = createQueryKeys('crazyAI', {
  images: ({ img, text }) => [{ img, text }],
});

export function ImageSuggestions({ usedVoice, setUsedVoice }) {
  const { useSelector, machine } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);
  const voice = useSelector(({ context }) => context.voice);
  const [selectedSuggestion, setSelectedSuggestion] = useState();
  const [stableImage, setStableImage] = useState(selectedElement.imgProps);
  const pageContext = useSelector(({ context }) => context.pageContext);
  const anonymousId = useSelector(({ context }) => context.anonymousId);
  const userId = useSelector(({ context }) => context.userId);
  const [queryStartTime, setQueryStartTime] = useState(null);

  const params = useMemo(
    () => ({
      voice: usedVoice,
      title: pageContext.title || '',
      meta_description: pageContext.meta_description || '',
      nearest_headline: selectedElement.closestHeadline || '',
      alt_text: getElementFromString(selectedElement.html).alt || '',
    }),
    [usedVoice, selectedElement, pageContext],
  );

  const context = useMemo(
    () => ({
      url: `${location.pathname}${location.search}`,
    }),
    [],
  );

  const { data, isLoading } = useCrazyAI({
    type: `page-editor-image`,
    params,
    context,
  });

  const {
    data: imageData,
    error,
    isFetching: imageLoading,
    refetch,
  } = useQuery({
    enabled: Boolean(data?.ready && !isLoading),
    context: CrazyAIQueryContext,
    ...queryKeys.images({ img: stableImage, text: data?.text }),
    queryFn: async ({ queryKey }) => {
      const [, , { img, text }] = queryKey;

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const image = await toDataURL(img.blob);
      const body = {
        model: AI_IMAGE_MODEL,
        text,
        negativeText: '',
        image,
        numberOfImages: SUGGESTION_COUNT,
        //width: selectedElement.width,
        //height: selectedElement.height,
      };

      const response = await fetch(AI_IMAGE_API, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error('Failed to generate image variant.');
      }
      const json = await response.json();
      //preload images
      await Promise.all(json.images.map((i) => loadImage(i)));

      return json;
    },
  });

  function applySuggestion(suggestion) {
    setSelectedSuggestion(suggestion);
    machine.send({ type: ACTIONS.SET_SELECTED_EL_IMAGE, url: suggestion, voice: usedVoice });
  }

  function revertSuggestion() {
    setSelectedSuggestion(null);
    machine.send({ type: ACTIONS.SET_SELECTED_EL_IMAGE, url: stableImage.url });
  }

  useEffect(() => {
    if (error) {
      machine.send({ type: ACTIONS.IMAGE_GENERATION_ERROR, error, img: stableImage });
    }
  }, [error, machine, stableImage]);

  const loading = !error && (isLoading || imageLoading || !imageData?.images);

  useEffect(() => {
    if (loading && !queryStartTime) {
      setQueryStartTime(performance.now());
    } else if (!loading && !error && queryStartTime) {
      sendMetric(`${METRICS_PREFIX}_element_image_generated`, {
        anonymous_id: anonymousId,
        user_id: userId,
        time_elapsed: Math.round(performance.now() - queryStartTime),
      });
      setQueryStartTime(null);
    } else if (!loading && error) {
      setQueryStartTime(null);
    }
  }, [loading, error, queryStartTime, userId, anonymousId]);

  if (loading) {
    return Array.from({ length: SUGGESTION_COUNT }).map((x, i) => <ImageSkeleton key={SUGGESTION_COUNT - i} />);
  }

  return (
    <>
      <div data-testid="ai-suggestions">
        {imageData?.images.map((img, i) => (
          <ImageSuggestion
            key={img}
            url={img}
            index={i}
            usedVoice={usedVoice}
            onClick={() => (selectedSuggestion === img ? revertSuggestion() : applySuggestion(img))}
            selected={selectedSuggestion === img}
            anySelected={!!selectedSuggestion}
          />
        ))}
      </div>
      <Transition
        show={!loading}
        enter="transition-opacity duration-700 delay-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <VoiceAndGenerate
          onClick={() => {
            setUsedVoice(getVoice(voice));
            setSelectedSuggestion(null);
            if (selectedSuggestion === stableImage.url) {
              refetch();
            } else {
              setStableImage(selectedElement.imgProps);
            }
          }}
        />
      </Transition>
    </>
  );
}

function ImageSkeleton() {
  return (
    <div
      className={classNames(
        'w-[240px] h-[150px] mt-2.5 rounded-md bg-gray-500/40 animate-skeleton',
        'bg-gradient-to-r from-gray-500/40 via-[20%] via-gray-500/10 to-[40%] to-gray-500/40 bg-[length:600px]',
      )}
    />
  );
}

function ImageSuggestion({ url, usedVoice, onClick, selected, anySelected, index }) {
  return (
    <button
      type="button"
      className={classNames(
        'text-sm text-left mt-2.5 p-1 rounded-md border',
        'relative',
        usedVoice === VOICES.CONFIDENT && {
          'bg-voice-confident/10 text-voice-confident': true,
          'border-voice-confident': selected,
          'border-voice-confident/50 hover:border-voice-confident opacity-50 hover:opacity-100':
            !selected && anySelected,
          'border-voice-confident/50 hover:border-voice-confident': !selected && !anySelected,
        },
        usedVoice === VOICES.ENGAGING && {
          'bg-voice-engaging/10 text-voice-engaging': true,
          'border-voice-engaging': selected,
          'border-voice-engaging/50 hover:border-voice-engaging opacity-50 hover:opacity-100': !selected && anySelected,
          'border-voice-engaging/50 hover:border-voice-engaging': !selected && !anySelected,
        },
        usedVoice === VOICES.FRIENDLY && {
          'bg-voice-friendly/10 text-voice-friendly': true,
          'border-voice-friendly': selected,
          'border-voice-friendly/50 hover:border-voice-friendly opacity-50 hover:opacity-100': !selected && anySelected,
          'border-voice-friendly/50 hover:border-voice-friendly': !selected && !anySelected,
        },
        usedVoice === VOICES.PROFESSIONAL && {
          'bg-voice-professional/10 text-voice-professional': true,
          'border-voice-professional': selected,
          'border-voice-professional/50 hover:border-voice-professional opacity-50 hover:opacity-100':
            !selected && anySelected,
          'border-voice-professional/50 hover:border-voice-professional': !selected && !anySelected,
        },
        usedVoice === VOICES.REASSURING && {
          'bg-voice-reassuring/10 text-voice-reassuring': true,
          'border-voice-reassuring': selected,
          'border-voice-reassuring/50 hover:border-voice-reassuring opacity-50 hover:opacity-100':
            !selected && anySelected,
          'border-voice-reassuring/50 hover:border-voice-reassuring': !selected && !anySelected,
        },
      )}
      onClick={onClick}
    >
      {selected && (
        <div
          className={classNames(
            'absolute top-0 right-0 w-[17px] h-[17px] rounded-bl rounded-tr flex items-center justify-center text-gray-700',
            {
              'bg-voice-confident': usedVoice === VOICES.CONFIDENT,
              'bg-voice-engaging': usedVoice === VOICES.ENGAGING,
              'bg-voice-friendly': usedVoice === VOICES.FRIENDLY,
              'bg-voice-professional': usedVoice === VOICES.PROFESSIONAL,
              'bg-voice-reassuring': usedVoice === VOICES.REASSURING,
            },
          )}
        >
          <TickIcon className="h-2 w-2 fill-current" aria-label="selected suggestion" />
        </div>
      )}
      <img src={url} alt={`Suggestion ${index + 1}`} />
    </button>
  );
}
