import { createContext } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const CrazyAIQueryContext = createContext();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: false,
      staleTime: 30 * 60 * 1000,
      queryFn: () => new Promise(() => {}),
    },
  },
});

export function CrazyAIProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient} context={CrazyAIQueryContext}>
      {children}
    </QueryClientProvider>
  );
}
