import { yupToFormErrors } from 'formik';

import { VOICES } from '../editor-ui/constants';

export function getId() {
  return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36);
}

export function shuffle(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function getVoice(voice) {
  if (voice == VOICES.LUCKY) {
    let voices = Object.values(VOICES).filter((v) => v !== VOICES.LUCKY);
    shuffle(voices);
    return voices[0];
  }
  return voice;
}

export function getValidationErrors(schema, value) {
  let errors;
  try {
    schema.validateSync(value, { abortEarly: false });
  } catch (yupErrors) {
    errors = yupToFormErrors(yupErrors);
  }
  return errors;
}

export async function throttleAsync(promises, batchSize) {
  let result = [];

  for (let i = 0; i < promises.length; i += batchSize) {
    const batch = promises.slice(i, i + batchSize);
    let batchResult = await Promise.all(batch.map((p) => p));
    result.push(...batchResult);
  }

  return result;
}

export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const onResolve = async () => {
      if (img.decode !== undefined) {
        await img.decode();
      }
      resolve(img.src);
    };
    const onReject = () => {
      reject(new Error('An Error occurred while trying to download an image'));
    };
    img.onload = onResolve;
    img.onerror = onReject;
    img.src = src;
  });
}
