import { PageInfo } from './PageInfo';
import { History } from './History';
import { ShareButton } from './ShareButton';
import { PublishButton } from './PublishButton';

import BalloonIcon from '@crazyegginc/hatch/dist/images/icon-logo-ce-balloon.svg?react';

export function BottomBar() {
  return (
    <div
      className="h-[60px] w-full bg-gray-900 shadow-bottomBar flex items-center justify-between px-5"
      data-testid="bottombar"
    >
      <div className="flex items-center w-1/3 shrink-0">
        <BalloonIcon className="fill-current w-8 h-8 mr-5" />
        <PageInfo />
      </div>
      <div className="flex items-center w-1/3 justify-center shrink-0">
        <History />
      </div>
      <div className="flex items-center w-1/3 space-x-2.5 justify-end shrink-0">
        <ShareButton />
        <PublishButton />
      </div>
    </div>
  );
}
