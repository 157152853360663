import { useState } from 'react';

import { useMainMachine } from '../../../utils/useMainMachine';

import { ACTIONS } from '../../../state';
import { HtmlContent } from './HtmlContent';
import { getVoice } from '../../../utils/utils';
import { VoiceAndGenerate } from './VoiceAndGenerate';
import { NarrowSelectedElement } from './NarrowSelectedElement';
import { ImageContent } from './ImageContent';

export function ElementEditor({ setUsedVoice }) {
  const { machine, useSelector } = useMainMachine();

  const voice = useSelector(({ context }) => context.voice);
  const selectedElement = useSelector(({ context }) => context.selectedElement);
  const [showContent] = useState(selectedElement.isEditable);

  return (
    <>
      {showContent ? (
        selectedElement.imgProps?.url ? (
          <ImageContent key={selectedElement.selector} />
        ) : (
          <HtmlContent key={selectedElement.selector} />
        )
      ) : (
        <NarrowSelectedElement key={selectedElement.selector} />
      )}
      <VoiceAndGenerate
        onClick={() => {
          machine.send({ type: ACTIONS.AI_REPHRASE });
          setUsedVoice(getVoice(voice));
        }}
      />
    </>
  );
}
